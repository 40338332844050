import React from "react"
import { graphql, Link } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import Layout from "../components/common/Layout"
import Idealline from "../components/idealline/."
import OnboardVideos from "../components/tracks/OnboardVideos"
import { ClientOnly, SvgGradient } from "../components/common"
import { MetaData } from '../components/common/meta'
import racingImage from '../images/racing-1600x900.jpg'

const SvgTrack = ({absoluteSvg}) => (
    <svg className="stroke-fill-medium" height={400} width="100%">
        <g filter="url(#dropshadow)">
            <use xlinkHref={`${absoluteSvg}#track`} />
            <use xlinkHref={`${absoluteSvg}#startfinish`} />
        </g>
    </svg>
)

const Track = ({ params, data, location }) => {
    const {
        trackgroup,
        place_name,
        place_rating,
        place_id,
        country,
        url,
        combinations,
    } = data.tracksGroupedJson
    const { startfinishlat: lat, startfinishlong: lng, svg } = combinations[0]

    let formattedName = trackgroup
    if (place_name.toLowerCase().includes(trackgroup.toLowerCase()))
        formattedName = place_name
    else if (place_name != "No result")
        formattedName = trackgroup + " - " + place_name

    const combSortedByLength = combinations.sort((a,b) => b.length - a.length)

    let title = `${formattedName}  Motorsport Track`
    let description = `${formattedName} track layout, racing lines and onboard Videos.`

    const line = data.line
    if(line) {
        title = `${line.vehicle ? line.vehicle : ''} Racing Line for ${formattedName} - Ideal Acceleration and Braking Points`
        description = `Racing line for ${formattedName}. Find your ideal, turn-in, braking and acceleration points on your track.`
    }

    const descriptionMeta = data.tracksDescriptionCsv?.description

    return (
        <>
            <MetaData
                title={title}
                description={description}
                location={location}
                image={racingImage}
            />
            <Layout>
                <article className="post-full {{ page.subclass }} {% unless page.cover %}no-image{% endunless %}">
                    <div className="row-full">
                        <div className="md:flex max-w-screen-2xl md:mx-auto md:mb-24">
                        <div className="md:w-1/2">
                    <header className="post-full-header">
                        <section className="post-full-meta">
                            <time className="post-full-meta-date">
                                <a href="/tracks">Race Track</a>
                            </time>
                            <span className="date-divider">/</span>
                            <>{country}</>
                        </section>
                        <h1 className="post-full-title">{formattedName}</h1>
                        <div className="flex mt-6 items-center">{descriptionMeta}</div>
                    </header>
                    </div>
                    <div className="md:w-1/2 flex items-center">
                    <SvgGradient />
                    {combinations.length == 1 ? (
                        <SvgTrack
                            absoluteSvg={`${process.env.GATSBY_SITEURL}/${svg}`}
                        />
                    ) : (
                        <Tabs selectedTabClassName="border-b-2 border-indigo-500 text-indigo-500">
                            {combSortedByLength.map(({ svg }) => (
                                <TabPanel key={svg}>
                                    <SvgTrack
                                        absoluteSvg={
                                            `${process.env.GATSBY_SITEURL}/${svg}`
                                        }
                                    />
                                </TabPanel>
                            ))}
                            <TabList className="container mx-auto md:flex flex-wrap flex-col">
                                <div className="flex mx-auto flex-wrap justify-center">
                                    {combSortedByLength.map(
                                        ({ name, length }) => (
                                            <Tab key={name} className="cursor-pointer sm:px-6 py-3 sm:w-auto justify-center sm:justify-start  title-font font-medium inline-flex items-center leading-none hover:text-indigo-900 tracking-wider">
                                                {name} ({length}m)
                                            </Tab>
                                        )
                                    )}
                                </div>
                            </TabList>

                        </Tabs>
                    )}</div></div>
                    </div>

                    <ClientOnly>
                        <Idealline
                            name={trackgroup}
                            placeId={place_id}
                            lat={lat}
                            lng={lng}
                            apikey={process.env.GATSBY_GMAPS_API_KEY}
                            zoom={17}
                            url={`/${url}`}
                        />
                    </ClientOnly>
                    <h1>Onboard Videos</h1>
                    <p>Find onboard videos for {formattedName} by using the search bar to fine tune your Youtube results. For example 'gt3', 'car' or any other awesome racer 👇</p>
                    <ClientOnly>
                        <OnboardVideos query={`${formattedName} onboard`} />
                    </ClientOnly>
                </article>
            </Layout>
        </>
    )
}

export default Track

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.
export const query = graphql`
    query ($id: String, $lineId: String, $trackgroup: String) {
        tracksGroupedJson(id: { eq: $id }) {
            trackgroup
            place_name
            place_rating
            place_id
            country
            url
            combinations {
                name
                startfinishlat
                startfinishlong
                length
                svg
            }
        }
        line(id: { in: [$lineId]}) {
            id
            vehicle
        }
        tracksDescriptionCsv(name: {eq: $trackgroup}) {
            description
        }
    }
`
